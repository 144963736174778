Vue.component('multiValueFilterComponent', {
    props: ['filterField'],
    data:function() {
        return {
            display:true,
            renderView:false,
            filterToApply:new Map(),
            collections:[],
            inView:0,
        };
    },
    watch: {
        '$route': 'generateFilters',
        '$store.state.itemTimeFlag':'generateFilters',
        '$store.state.itemUpdate':  'calculateResults'
    },
    computed:{
        getCollections(){
            let self= this;
            let collections = this.collections.slice();
            function sortCollectionName(a,b) {
                let aname= a.label;
                let bname = b.label;
                if(self.filterField.ItemField){
                    aname= self.tr(a.label);
                    bname =self.tr(b.label);
                }
                return (aname > bname ? 1 : ((bname > aname) ? -1 : 0))
            }
            return collections.sort(sortCollectionName)
        },
    },
    mounted:function(){
        this.generateFilters();
    },
    methods:{
        orderCollections(collections){
            if(collections.length>0) {
                let self = this;
                function sortCollectionName(a, b) {
                    //console.log(a, b)
                    let aname = a.label;
                    let bname = b.label;
                    if (self.filterField.ItemField) {
                        aname = self.$store.getters.tr(a.label);
                        bname = self.$store.getters.tr(b.label);
                    }
                    return (aname > bname ? 1 : ((bname > aname) ? -1 : 0))
                }
                //console.log(collections.sort(sortCollectionName));
                this.collections =collections.sort(sortCollectionName);
            }
        },
        fetchData(filter,value) {
            //console.log('Event Capture',filter,value);
            this.renderView=!this.renderView;
            this.calculateResults();
        },
        async resetFilterActive(){
          /*for(let activeFilter of this.collections)
              if(activeFilter.active) {
                  activeFilter.active=false;
                  let res = await this.$store.dispatch('addFilter', activeFilter);
                  console.log(res);
              }*/
          this.calculateResults();
        },
        resetCountFilters:function(){
            this.inView=0;
            for(let filter of this.collections) {
                filter.itemCount = 0;
            }
        },
        calculateResults: function(){
            //console.log('Calculate Results Multivalue For ',this.filterField.ItemField);
            //console.time('Calculate Result-Multivalue -'+this.filterField.ItemField);
            let items = this.$store.getters.getItemsVisibles; //this.$store.getters.getItems;
            this.resetCountFilters();
            for(let filter of this.collections) {
                let counter = 1
                for (let x of items.filter((e) => e.visible == true)) {
                    try {
                        let cond = filter.condition;
                        let result = filter.result;
                        let isPresent = RegExp(filter.label, 'gi');
                        let isVisible = false;
                        //console.log(counter,x[filter.filterField],filter.label)
                        //counter = counter+1;
                        if (x[filter.filterField] != null) {
                            //console.time(filter.label + 'RexTime' + x.Code);
                            isVisible = isPresent.test(x[filter.filterField]);
                            //console.timeEnd(filter.label + 'RexTime' + x.Code);
                            //console.log(this.filterField.ItemField, isPresent, x[this.filterField.ItemField], isVisible);

                            /*console.time(filter.label+'IndexOfTime-'+x.Code)
                            isVisible = x[this.filterField.ItemField].split(",").indexOf(filter.label);
                            console.time('IndexOfTime'+x.Code)

                            console.time(filter.label+'FunctionOfTime'+x.Code)
                            let tmpFunc2 = new Function("x","return "+cond);
                            isVisible =tmpFunc2(x)==result;
                            console.timeEnd(filter.label+'FunctionOfTime'+x.Code)*/

                            /*console.time(filter.label+'EvalFunctionOfTime'+x.Code)
                            isVisible = (eval(cond) == result)
                            console.timeEnd(filter.label+'EvalFunctionOfTime'+x.Code)-*/
                        }
                        if (isVisible) {
                            filter.itemCount = filter.itemCount + 1;
                            this.inView = this.inView + 1;
                        }
                        filter.active = this.$store.state.filters.indexOf(filter.name) !== -1;
                    }
                    catch (err) {
                        console.log(err, filter, x);
                    }
                }
            }
            //console.timeEnd('Calculate Result-Multivalue -'+this.filterField.ItemField);
        },
        generateFilters:function(){
            let ui =uuidv4();
            //console.group('Multivalue '+ui);
            //console.time('Multivalue -'+this.filterField.ItemField);
            let collection = new Map();
            let items = this.$store.getters.getItemsVisibles; ///this.$store.getters.getItems; // Array.from(this.$store.state.items_store.values());
            for(let item of items) {
                let value = this.getItemValue(item,this.filterField.ItemField);
                if (value) {
                    if (!collection.has(value)) {
                        let filter = {};
                        filter.name = `multivalue-${this.filterField.ItemField}-${value}`  //uuidv4();
                        filter.condition = `x.${this.filterField.ItemField}.split(',').indexOf(\`${value}\`)!=-1`;
                        filter.result = true;
                        filter.type='multivalue';
                        filter.label = value;
                        filter.filterField = this.filterField.ItemField;
                        filter.itemCount = 0;
                        filter.hiddens=new Map();
                        filter.active= false
                        collection.set(value,filter);
                    }
                }
            }
            /*orderCollections(collections)
            this.collections = Array.from(collection.values());*/
            this.orderCollections(Array.from(collection.values())) ;
            this.calculateResults();
            //console.timeEnd('Multivalue -'+this.filterField.ItemField);
            //console.groupEnd('Multivalue '+ui);
        },
        getItemValue:function(item,fieldName){
            if(item.fields)
                item=item.fields;
            return item[fieldName]
        },
        checkedObserve(filter){
            filter.active= true & filter.active;
            //console.log('Log MultiValueFilter', filter);
            this.$store.dispatch('addFilter',filter);
            window.scrollTo(0, 0);
        }
    },
    template: `<div class="filter" v-show="inView>0">
                    <div>
                        <h5 class="custom-title">
                            {{tr(filterField.ItemField)}}
                            <span v-show="display" @click="display = !display" class="right"><i class="icon fa fa-minus"></i></span>
                            <span v-show="!display" @click="display = !display" class="right"><i class="icon fa fa-plus"></i></span>
                        </h5>
                    </div>
                    <section v-show="display" class="filter-options">
                        <ul>
                          <template v-for="value of getCollections">
                                <li :key="'li'+value.name" v-if="value.itemCount>0">
                                    <input :key="'input'+value.name" type="checkbox" v-model="value.active" :id="value.label" @click="checkedObserve(value)" />
                                    <template v-if="filterField.FilterMustTranslated">
                                        <label :for="value.label" style="max-width: 90%;">{{tr(value.label)}}</label>
                                    </template>
                                    <template v-else>
                                        <label :for="value.label" style="max-width: 90%;">{{value.label}}</label>        
                                    </template>
                                    <label :for="value.label" class="right">{{value.itemCount}}</label>
                                </li>
                          </template>
                        </ul>
                    </section>
               </div>`,
});
